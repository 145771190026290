import { React, useEffect, useState, useRef } from 'react'
import {
  Backdrop,
  CircularProgress,
  Container,
  TextField,
  MenuItem,
  Grid,
  FormControl,
  Button,
  TableContainer,
  TableHead,
  RadioGroup,
  TableBody,
  FormControlLabel,
  TableRow,
  Radio,
  TableCell,
  Table,
  Paper,
  tableCellClasses,
  Typography,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DownloadIcon from '@mui/icons-material/Download'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { styled } from '@mui/material/styles'
import $http, { $download } from 'src/utils/api/services'
import * as api from '../../../utils/api/workerAttendanceRecord/AccRecSearchApi'
import * as reportApi from '../../../utils/api/workerAttendanceRecord/AccRecVendorApprovedSearch'

export default function AccRecVendorApprovedSearch() {
  const [isSearchPage, setIsSearchPage] = useState(true)
  const { t } = useTranslation()
  const printComponentRef = useRef()
  const [backOpen, setBackOpen] = useState(false)
  const [siteList, setSiteList] = useState([])
  const [subList, setSubList] = useState([])
  const [sendData, setSendData] = useState({})
  const [tableData, setTableData] = useState({})
  const [orderType, setOrderType] = useState('ASC')
  const [vendorDesc, setVendorDesc] = useState({})

  useEffect(() => {
    setBackOpen(true)
    const getData = async () => {
      let getSiteList = await api.getSiteList()
      // let getSmartCard = await api.getSmartCard();
      let getVendorDesc = await api.getVendorDesc()
      if (getSiteList) {
        setSiteList(getSiteList)
      }
      if (getVendorDesc) {
        setVendorDesc(getVendorDesc)
      }
      setSendData({
        ...sendData,
        downloadType: 'Screen',
        orderBy: 'englishName',
        effectiveDate: moment(new Date()).format('yyyy/MM/DD'),
      })
      setBackOpen(false)
    }

    getData()
  }, [])

  const handleBack = () => {
    setIsSearchPage(true)
  }

  const handleSiteId = (data) => {
    setBackOpen(true)
    const getSub = async () => {
      let getSubConList = await api.getSubContractorList(data.id)
      if (getSubConList) {
        setSubList(getSubConList)
      }

      setSendData({
        ...sendData,
        siteId: data.id,
        siteDesc: data.text,
        subcontractorId: '',
      })
      setBackOpen(false)
    }
    getSub()
  }

  const handleSubcontractors = (data) => {
    setSendData({
      ...sendData,
      subcontractorId: data.id,
      subContractorDesc: data.text,
    })
  }
  const handleEffectiveDate = (e) => {
    setSendData({
      ...sendData,
      effectiveDate: moment(new Date(e.target.value).getTime()).format('yyyy/MM/DD'),
      dateTo: '',
    })
  }

  const handleDateTo = (e) => {
    setSendData({
      ...sendData,
      dateTo: moment(new Date(e.target.value).getTime()).format('yyyy/MM/DD'),
    })
  }

  const handleOrderBy = (e) => {
    setSendData({ ...sendData, orderBy: e.target.value })
  }

  const handleOrderType = (e) => {
    setOrderType(e.target.value)
    setSendData({ ...sendData, ascOrder: e.target.value === 'ASC' })
  }

  const handleDownloadType = (e) => {
    setSendData({ ...sendData, downloadType: e.target.value })
  }

  const handleTOC = (e) => {
    setSendData({ ...sendData, toc: e.target.value })
  }

  const handleFace = (e) => {
    setSendData({ ...sendData, face: e.target.value })
  }

  const handleSubmit = (e) => {
    if (!sendData.siteId) {
      alert('请选择地盘！！！')
      return
    }
    if (!sendData.effectiveDate) {
      alert('请选择日期！！！')
      return
    }
    const getData = async () => {
      if (sendData.downloadType === 'Screen') {
        setBackOpen(true)
        let send = await reportApi.getReport(sendData)
        if (send) {
          setIsSearchPage(false)
          setTableData(send)
        }
      }
      if (sendData.downloadType === 'Excel') {
        setBackOpen(true)
        exportExcel(sendData)
      } else if (sendData.downloadType === 'PDF') {
        setBackOpen(true)
        exportPDF(sendData)
      }
      setBackOpen(false)
    }
    getData()
  }

  const resetForm = (e) => {
    setSendData({})
  }

  const exportExcel = (data) => {
    $download(`/attendance/report/getAccRecVendorApprovedListExcel`, 'application/vnd.ms-excel', data)
  }

  const exportPDF = (data) => {
    $download(`/attendance/report/getAccRecVendorApprovedListPdf`, 'application/pdf', data)
  }

  const ReportTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      wordBreak: 'keep-all',
      whiteSpace: 'nowrap',
      fontSize: '15px',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 15,
    },
  }))

  const ReportTableRow = styled(TableRow)(({ theme }) => ({}))

  return (
    // 核准承判商工人進入記錄表
    <>
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={backOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container>
        {isSearchPage && (
          <div>
            <form>
              <Container maxWidth="md">
                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h4" style={{ textAlign: 'center' }}>
                      <b>{t('icon1_accrecvendorapprovedsearch_title')}</b>
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <TextField error={!sendData.siteId} id="siteDesc" value={sendData.siteId || ''} fullWidth size="small" label={t('Site_Name')} select>
                      <MenuItem value="" onClick={() => handleSiteId({ id: null, text: 'All' })}>
                        None
                      </MenuItem>
                      {siteList.map((item, index) => (
                        <MenuItem key={index} value={item.id} name={item.text} onClick={() => handleSiteId(item)}>
                          {item.id} - {item.text}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField id="subcontractorDesc" value={sendData.subcontractorId || ''} fullWidth size="small" label={t('Subcontractors_(direct employers)')} select>
                      <MenuItem value="" onClick={() => handleSubcontractors({ id: null, text: 'All' })}>
                        None
                      </MenuItem>
                      {subList.map((item, index) => (
                        <MenuItem key={index} value={item.id} onClick={() => handleSubcontractors(item)}>
                          {item.text}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={!sendData.effectiveDate}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        max: '2099-12-31',
                      }}
                      value={moment(new Date(sendData.effectiveDate).getTime()).format('yyyy-MM-DD') || ''}
                      fullWidth
                      size="small"
                      label={t('icon1_useraccesslog_valid_date_label')}
                      type="date"
                      onChange={handleEffectiveDate}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField value={sendData.toc || ''} fullWidth size="small" label={t('icon1_useraccesslog_template_on_card_label')} select onChange={handleTOC}>
                      <MenuItem value={null}>None</MenuItem>
                      <MenuItem value="N">N</MenuItem>
                      <MenuItem value="Y">Y</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField value={sendData.face || ''} fullWidth size="small" label={t('icon1_useraccesslog_face_label')} select onChange={handleFace}>
                      <MenuItem value={null}>None</MenuItem>
                      <MenuItem value="N">N</MenuItem>
                      <MenuItem value="Y">Y</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField value={sendData.orderBy || ''} fullWidth size="small" label={t('icon1_useraccesslog_order_label')} select onChange={handleOrderBy}>
                      <MenuItem value={null}>None</MenuItem>
                      <MenuItem value="chineseName">Chinese Name</MenuItem>
                      <MenuItem value="englishName">English Name</MenuItem>
                      <MenuItem value="smartCardId">Smart Card Id</MenuItem>
                      <MenuItem value="subContractorDesc">SubContractor Desc</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField defaultValue="Screen" value={sendData.downloadType || ''} fullWidth size="small" label={t('icon1_useraccesslog_export_type_label')} select onChange={handleDownloadType}>
                      <MenuItem value="Screen">Screen</MenuItem>
                      <MenuItem value="Excel">Excel</MenuItem>
                      <MenuItem value="PDF">PDF</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl size="small">
                      <RadioGroup row onChange={handleOrderType} defaultValue={orderType}>
                        <FormControlLabel value="ASC" label="ASC" control={<Radio />} />
                        <FormControlLabel value="DESC" label="DESC" control={<Radio />} />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} />
                  <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <FormControl>
                      <Button variant="contained" onClick={handleSubmit}>
                        {t('button_submit')}
                      </Button>
                    </FormControl>
                    <FormControl sx={{ marginLeft: '20px' }}>
                      <Button
                        variant="contained"
                        onClick={resetForm}
                        label="Reset"
                        sx={{
                          backgroundColor: 'primaryGreen',
                          color: 'white',
                          marginRight: '10px',
                        }}
                      >
                        {t('button_reset')}
                      </Button>
                    </FormControl>
                  </Grid>
                </Grid>
              </Container>
            </form>
          </div>
        )}
      </Container>

      {!isSearchPage && (
        <Container maxWidth="false">
          <div
            style={{
              display: 'block',
              textAlign: 'left',
              marginTop: '0px',
            }}
          >
            <Button sx={{ float: 'left', mr: '20px' }} onClick={handleBack}>
              <ArrowBackIcon />
              {t('button_back')}
            </Button>
          </div>
          <div style={{ display: 'block', textAlign: 'right' }}>
            <Button sx={{ float: 'right', mr: '20px' }} onClick={() => exportExcel(sendData)}>
              <DownloadIcon />
              {t('button_export_excel')}
            </Button>
            <Button sx={{ float: 'right', mr: '20px' }} onClick={() => exportPDF(sendData)}>
              <DownloadIcon />
              {t('button_export_pdf')}
            </Button>
          </div>
          <div ref={printComponentRef}>
            <Typography variant="h4" style={{ textAlign: 'center' }}>
              <b>{t('icon1_accrecvendorapprovedsearch_title')}</b>
            </Typography>
            <TableContainer>
              <Table sx={{ textAlign: 'left' }} size="small">
                <TableBody>
                  <TableRow>
                    {/* 地盤名稱 */}
                    <TableCell
                      sx={{
                        width: 200,
                        borderBottom: 'none',
                        fontSize: '16px',
                      }}
                    >
                      {t('Site_Name')}:
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: 'left',
                        borderBottom: 'none',
                        fontSize: '16px',
                      }}
                    >
                      {sendData.siteDesc}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    {/* 承判商: 	 */}
                    <TableCell
                      sx={{
                        width: 200,
                        borderBottom: 'none',
                        fontSize: '16px',
                      }}
                    >
                      {t('Contractor')}：
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: 'left',
                        borderBottom: 'none',
                        fontSize: '16px',
                      }}
                    >
                      {vendorDesc}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    {/* 分判商: 	 */}
                    <TableCell
                      sx={{
                        width: 200,
                        borderBottom: 'none',
                        fontSize: '16px',
                      }}
                    >
                      {t('Subcontractors')}：
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: 'left',
                        borderBottom: 'none',
                        fontSize: '16px',
                      }}
                    >
                      {sendData.subContractorDesc ? sendData.subContractorDesc : 'All'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    {/* 總數: */}
                    <TableCell sx={{ borderBottom: 'none', fontSize: '16px' }}>{t('icon1_accrecsearch_total_condition')}：</TableCell>
                    <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>
                      <Typography sx={{ fontSize: '16px' }}>{tableData ? tableData.length : 0}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <TableContainer sx={{ maxHeight: '80vh', maxWidth: '100vw' }}>
              <Table size="small" stickyHeader>
                <TableHead>
                  <ReportTableRow>
                    <ReportTableCell>{t('icon1_accrecsearch_sub_condition')}</ReportTableCell>
                    <ReportTableCell>{t('Subcontractors_(direct employers)')}</ReportTableCell>
                    <ReportTableCell>{t('icon1_accrecvendorapprovedsearch_smart_card_column')}</ReportTableCell>
                    <ReportTableCell>{t('icon1_accrecvendorapprovedsearch_cn_name_column')}</ReportTableCell>
                    <ReportTableCell>{t('icon1_accrecvendorapprovedsearch_eng_name_column')}</ReportTableCell>
                    <ReportTableCell>{t('icon1_accrecvendorapprovedsearch_work_type_column')}</ReportTableCell>
                    <ReportTableCell>{t('icon1_accrecvendorapprovedsearch_valid_date_column')}</ReportTableCell>
                    <ReportTableCell>{t('icon1_accrecvendorapprovedsearch_cwra_card_no_column')}</ReportTableCell>
                    <ReportTableCell>{t('icon1_accrecvendorapprovedsearch_cwra_card_column')}</ReportTableCell>
                    <ReportTableCell>{t('icon1_accrecvendorapprovedsearch_driver_column')}</ReportTableCell>
                    <ReportTableCell>{t('icon1_accrecvendorapprovedsearch_template_on_card_column')}</ReportTableCell>
                    <ReportTableCell>{t('FACE')}</ReportTableCell>
                  </ReportTableRow>
                </TableHead>
                {!!tableData && tableData.length > 0 && (
                  <TableBody>
                    {tableData.map((row, ind) => (
                      <TableRow
                        hover
                        key={ind}
                        sx={{
                          backgroundColor: ind % 2 !== 0 ? '#DCDCDC' : '#ffffff',
                        }}
                      >
                        <ReportTableCell>{row.vendorDesc}</ReportTableCell>
                        <ReportTableCell component="th" scope="row">
                          {row.subContractorDesc}
                        </ReportTableCell>
                        <ReportTableCell>{row.smartCardId}</ReportTableCell>
                        <ReportTableCell>{row.chineseName}</ReportTableCell>
                        <ReportTableCell>{row.englishName}</ReportTableCell>
                        <ReportTableCell>{row.jobNatureDesc}</ReportTableCell>
                        <ReportTableCell>{row.effectiveDate}</ReportTableCell>
                        <ReportTableCell>{row.cwraCardNo}</ReportTableCell>
                        <ReportTableCell>{row.cwraCardStatusDesc}</ReportTableCell>
                        <ReportTableCell>{row.driver}</ReportTableCell>
                        <ReportTableCell>{row.templateOnCard}</ReportTableCell>
                        <ReportTableCell>{row.faceId}</ReportTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}

                {(!tableData || tableData.length === 0) && (
                  <TableBody>
                    <ReportTableRow>
                      <ReportTableCell sx={{ textAlign: 'center' }} colSpan={11}>
                        {'No data found!'}
                      </ReportTableCell>
                    </ReportTableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </div>
        </Container>
      )}
    </>
  )
}
